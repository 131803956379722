// 'Social List' styled component
import React from "react"
import styled from 'styled-components'
import '../utils/all.min.js'

const SocialList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
const SocialListItem = styled.li`
  display: inline;
  margin-right: .5rem;
    &:last-child {
      margin-right: 0;
    }
`;
const SocialListItemLink = styled.a`
  box-shadow: none;
`;

export default () => (
  <SocialList>
   <SocialListItem><SocialListItemLink href="https://twitter.com/macyuppie" title="Twitter"><i className="fab fa-twitter fa-2x" style={{ color: 'dodgerblue' }}></i></SocialListItemLink></SocialListItem>
   <SocialListItem><SocialListItemLink href="https://facebook.com/macyuppie" title="Facebook"><i className="fab fa-facebook fa-2x" style={{ color: 'darkblue' }}></i></SocialListItemLink></SocialListItem>
   <SocialListItem><SocialListItemLink href="https://instagram.com/macyuppie" title="Instagram"><i className="fab fa-instagram fa-2x" style={{ color: 'blue' }}></i></SocialListItemLink></SocialListItem>
  </SocialList>
)